<template>
  <div class="create-company">
    <Banner>
      <div class="banner-content">
        <MeepIconSettings class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.setting") }}</span>
      </div>
    </Banner>

    <div class="create-company-content page-layout">
      <LoadingCard v-if="isLoading" />

      <md-card v-else class="meep-form">
        <PageHeader
          :title="$t('create-company.title')"
          :has-back="true"
          :has-search="false"
          :has-actions="false"
          @back="goBack"
        />
        <!-- le formulaire de création -->
        <md-card-content>
          <!-- Numéro de SIREN -->
          <div class="meep-form-column">
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('siren') }">
                <label>{{ $t("create-company.siren") }}</label>

                <md-input
                  v-model="company.siren"
                  v-validate="'required|numeric|min:9|max:9'"
                  name="siren"
                  type="text"
                >
                </md-input>

                <span v-show="errors.has('siren')" class="md-error">
                  {{ errors.first("siren") }}
                </span>
              </md-field>
            </div>

            <!-- Nom -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('name') }">
                <label>{{ $t("create-company.name") }}</label>

                <md-input
                  v-model="company.name"
                  v-validate="{
                    required: true,
                  }"
                  name="name"
                  type="text"
                >
                </md-input>

                <span v-show="errors.has('name')" class="md-error">
                  {{ errors.first("name") }}
                </span>
              </md-field>
            </div>

            <!-- Nom et prénom du dirigeant -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('firstName') }">
                <label>{{
                  company.createUser
                    ? $t("create-company.firstName")
                    : editRequiredForm($t("create-company.firstName"))
                }}</label>

                <md-input
                  v-model="company.firstName"
                  v-validate="{
                    alpha_spaces: true,
                    required: company.createUser,
                  }"
                  name="firstName"
                  type="text"
                >
                </md-input>

                <span v-show="errors.has('firstName')" class="md-error">
                  {{ errors.first("firstName") }}
                </span>
              </md-field>
            </div>

            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('lastName') }">
                <label>{{
                  company.createUser
                    ? $t("create-company.lastName")
                    : editRequiredForm($t("create-company.lastName"))
                }}</label>

                <md-input
                  v-model="company.lastName"
                  v-validate="{
                    alpha_spaces: true,
                    required: company.createUser,
                  }"
                  name="lastName"
                  type="text"
                >
                </md-input>

                <span v-show="errors.has('lastName')" class="md-error">
                  {{ errors.first("lastName") }}
                </span>
              </md-field>
            </div>

            <!-- Email -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('email') }">
                <label>{{
                  company.createUser
                    ? $t("create-company.email")
                    : editRequiredForm($t("create-company.email"))
                }}</label>

                <md-input
                  v-model="company.email"
                  v-validate="{
                    required: company.createUser ? true : false,
                    email: true,
                  }"
                  name="email"
                  type="email"
                >
                </md-input>

                <span v-show="errors.has('email')" class="md-error">
                  {{ errors.first("email") }}
                </span>
              </md-field>
            </div>

            <!-- Numéro de téléphone -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('tel') }">
                <label>{{ $t("create-company.tel") }}</label>

                <md-input
                  v-model="company.tel"
                  v-validate="'telephone'"
                  name="tel"
                  type="tel"
                >
                </md-input>

                <span v-show="errors.has('tel')" class="md-error">
                  {{ errors.first("tel") }}
                </span>
              </md-field>
            </div>

            <!-- Site Web -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('url') }">
                <label>{{ $t("create-company.url") }}</label>

                <md-input
                  v-model="company.url"
                  v-validate="'url'"
                  name="url"
                  type="url"
                >
                </md-input>

                <span v-show="errors.has('url')" class="md-error">
                  {{ errors.first("url") }}
                </span>
              </md-field>
            </div>
          </div>
          <div class="meep-form-column">
            <!-- Numéro et voie -->
            <div class="meep-input">
              <md-field>
                <label>Adresse</label>

                <md-input v-model="company.adresse" name="adresse" type="text">
                </md-input>
              </md-field>
            </div>

            <!-- Ville -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('ville') }">
                <label>Ville</label>

                <md-input
                  v-model="company.ville"
                  v-validate="'alpha_spaces'"
                  name="ville"
                  type="ville"
                >
                </md-input>

                <span v-show="errors.has('ville')" class="md-error">
                  {{ errors.first("ville") }}
                </span>
              </md-field>
            </div>

            <!-- Code postal -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('codepostal') }">
                <label>Code postal</label>

                <md-input
                  v-model="company.codepostal"
                  v-validate="'numeric|min:5|max:5'"
                  name="codepostal"
                  type="text"
                >
                </md-input>

                <span v-show="errors.has('codepostal')" class="md-error">
                  {{ errors.first("codepostal") }}
                </span>
              </md-field>
            </div>

            <!-- Groupes -->
            <div class="meep-input">
              <md-field>
                <label for="groups"> Groupes </label>

                <md-select
                  id="groups"
                  v-model="company.groups"
                  multiple
                  name="groups"
                >
                  <md-option
                    v-for="(group, index) of groups"
                    :key="index"
                    :value="group.id"
                  >
                    {{ group.name }}
                  </md-option>
                  <md-option
                    v-if="groups.length === 0"
                    key="-1"
                    disabled
                    value=""
                  >
                    {{ $t("create-company.accune") }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <!-- cloud synchroniser les e-mails -->
            <div v-if="self.organization.has_email_synchro" class="meep-input">
              <md-field
                :class="{ 'md-invalid': errors.has('cloud_synchronize_email') }"
              >
                <label>{{ $t("company.cloud_synchronize_email") }}</label>

                <md-input
                  v-model="company.cloud_synchronize_email"
                  v-validate="'email'"
                  name="cloud_synchronize_email"
                  type="text"
                >
                </md-input>

                <span
                  v-show="errors.has('cloud_synchronize_email')"
                  class="md-error"
                >
                  {{ errors.first("cloud_synchronize_email") }}
                </span>
              </md-field>
            </div>

            <md-checkbox
              v-model="company.createUser"
              class="meep-form__checkbox-bold"
            >
              Créer aussi le compte client
            </md-checkbox>

            <div class="md-layout md-alignment-center-right">
              <md-button
                class="md-raised md-primary meep-form__submit-btn"
                @click.native="openConfirmGlobalModal('create')"
              >
                {{ $t("companies.create.button") }}
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <confirm-action-modal
      v-if="isConfirmGlobalModalOpen"
      :text="confirmGlobalModalText"
      :object-to-act-upon="{}"
      @close="closeConfirmGlobalModal"
      @confirm="onConfirmModal"
    />
  </div>
</template>

<script>
import companiesModel from "../../model/companies";
import PageHeader from "@/components/PageHeader";
import Banner from "@/components/Banner";
import MeepIconSettings from "@/components/icons/MeepIconSettings.vue";
import LoadingCard from "@/components/LoadingCard";
import groupesModel from "../../model/groupes";
import confirmActionModal from "@/components/modal/confirm-action";
import { mapGetters } from "vuex";

export default {
  name: "CreateCompany",

  components: {
    LoadingCard,
    Banner,
    MeepIconSettings,
    PageHeader,
    confirmActionModal,
  },

  data() {
    return {
      groups: [],
      isLoading: false,
      company: {
        siren: "",
        name: "",
        email: "",
        tel: "",
        url: "",
        adresse: "",
        ville: "",
        firstName: "",
        lastName: "",
        codepostal: null,
        groups: [],
        createUser: true,
        cloud_synchronize_email: "",
      },
      forceLeave: false,
      isAnyModified: false,
      isConfirmGlobalModalOpen: false,
      nextPath: "",
      confirmCreateContext: "",
    };
  },

  computed: {
    ...mapGetters(["self", "isJEPA"]),

    confirmGlobalModalText() {
      if (this.confirmCreateContext === "create") {
        return {
          header: this.$t("Entreprises"),
          body: () => {
            return this.$t(
              `Vous allez créer l’entreprise ${this.company.name}.`
            );
          },
          question: this.$t("Voulez-vous continuer ?"),
        };
      } else {
        return {
          header: this.$t("companies.create.unsaved-modal.header"),
          body: () => {
            return this.$t("companies.create.unsaved-modal.body");
          },
          question: this.$t("companies.create.unsaved-modal.question"),
        };
      }
    },
  },

  created() {
    this.loadGroups();
  },

  methods: {
    goBack() {
      this.text = "goBack";
      this.confirmCreateContext = "goBack";
      window.history.back();
    },

    async createCompany() {
      this.isLoading = true;
      try {
        await companiesModel.create({
          ...this.company,
          folder_preset: this.isJEPA ? 2 : 1,
        });
        this.isLoading = false;

        this.$toasted.global.AppSucces({
          message: "L'entreprise a bien été créée",
        });

        this.forceLeave = true;
        this.$router.push("/dashboard/setting/companies/");
      } catch (err) {
        this.isLoading = false;

        this.$toasted.global.AppError({
          message: err.msg,
        });
      }
    },

    async loadGroups() {
      try {
        this.isLoading = true;

        this.groups = await groupesModel.getAll();

        this.isLoading = false;
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });

        this.isLoading = false;
      }
    },

    editRequiredForm(stringData) {
      return stringData.slice(0, stringData.length - 1);
    },

    async openConfirmGlobalModal(text) {
      if (text === "create") {
        const result = await this.$validator.validateAll();
        if (result) {
          this.confirmCreateContext = text;
          this.isConfirmGlobalModalOpen = true;
        } else {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });
        }
      } else {
        this.isConfirmGlobalModalOpen = true;
      }
    },

    closeConfirmGlobalModal() {
      this.isConfirmGlobalModalOpen = false;
    },

    onConfirmModal() {
      if (this.confirmCreateContext === "create") {
        this.createCompany();
      } else {
        this.forceLeave = true;
        this.$router.push(this.nextPath);
      }
    },
  },

  watch: {
    company: {
      handler: function () {
        this.isAnyModified = true;
      },
      deep: true,
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.forceLeave) {
      next();
    }

    if (this.isAnyModified) {
      this.openConfirmGlobalModal();
      this.nextPath = to.fullPath;
      next(false);
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
.create-company {
  &-content {
    padding: 30px;
  }
  .md-field.md-theme-default {
    input[name="adresse"] {
      margin: 0;
    }
  }
}
</style>
