<template>
  <modal class="confirm-action-modal" @close="$emit('close')">
    <div class="modal-header">
      <div
        :class="isGC ? 'app-logo-minified' : 'app-logo'"
        v-html="isGC ? logoMinified : logo"
      />
    </div>

    <div class="modal-body">
      <div class="modal-body-title">
        <slot name="icon"
          ><MeepIconExclamationMark class="app-icon app-icon-exclamation"
        /></slot>
        {{ text.header }}
      </div>
      <div class="modal-body-content">
        <p class="modal-body-context" v-html="text.body(objectToActUpon)"></p>
        <p>{{ text.question }}</p>
      </div>
    </div>

    <div class="modal-footer">
      <md-button class="md-primary--inverted" @click.native="$emit('close')">
        Annuler
      </md-button>

      <md-button class="md-raised md-primary" @click.native="onConfirm">
        Oui
      </md-button>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import MeepIconExclamationMark from "@/components/icons/MeepIconExclamationMark.vue";

export default {
  name: "ConfirmActionModal",
  components: {
    MeepIconExclamationMark,
  },

  props: {
    text: {
      type: Object,
      required: true,
    },
    objectToActUpon: {
      type: [Object, Array],
      required: true,
    },
  },
  computed: {
    ...mapGetters(["logo", "logoMinified", "isGC"]),
  },
  methods: {
    onConfirm() {
      this.$emit("confirm");
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";
#app .confirm-action-modal {
  .modal {
    &-container {
      min-width: 638px;
      padding: 0 0 toRem(45) toRem(27);
      border-radius: 27px;
      margin-bottom: 10px;
      @include for-lg {
        padding: toRem(10) toRem(64) toRem(50) toRem(38);
        min-height: 350px;
        min-width: 950px;
      }
      .app-logo {
        svg {
          width: 105px;
        }
      }
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-body {
      padding: 0 20px;

      &-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: var(--modal-title-size-lg);
        font-family: var(--font-bold);
        margin-bottom: 15px;
        @include for-lg {
          font-size: var(--modal-title-size-xl);
        }
        .app-icon {
          width: var(--modal-icon-size-lg);
          height: var(--modal-icon-size-lg);
          margin-right: 15px;
          @include for-lg {
            width: var(--modal-icon-size-xl);
            height: var(--modal-icon-size-xl);
          }
          &-big {
            width: 75px;
            height: 75px;
            margin-right: 15px;
          }
        }

        .app-icon:not(.app-icon-default) {
          path {
            stroke: var(--bg-primary);
          }
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .modal-body-context {
          font-size: toRem(14);
          font-family: var(--font-bold);
          line-height: 22px;
          margin-bottom: 6px;
          text-align: center;
          @include for-lg {
            font-size: toRem(22);
          }
        }

        p {
          font-size: 17px;
          font-family: var(--font-x);
        }
      }
    }

    &-footer {
      padding-top: 20px;
      display: flex;
      justify-content: center;

      .md-button {
        height: 30px;
        @include for-lg {
          height: 45px;
        }
      }
    }
  }
}
</style>
