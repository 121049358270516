import fetch from "../services/fetch";
import store from "../store";

export default {
    get,
    getAll,
    create,
    update,
    remove,
    addCompany,
    removeCompany,
};

function get(id) {
    return fetch("api/groupes/" + id, {
        method  : "get",
        headers : { "Authorization" : store.state.token },
    });
}

function getAll(params) {
    return fetch("api/groupes", {
        method  : "get",
        headers : { "Authorization" : store.state.token },
        params  : params,
    });
}

function create({ name, companies }) {
    return fetch("api/groupes/", {
        method  : "post",
        headers : {
            "Authorization" : store.state.token,
            "Content-Type"  : "application/json",
        },
        body    : JSON.stringify({
            name,
            company_ids : companies,
        }),
    });
}

function update(groupe) {
    return fetch("api/groupes/" + groupe.id, {
        method  : "put",
        headers : {
            "Authorization" : store.state.token,
            "Content-Type"  : "application/json",
        },
        body    : JSON.stringify({ name : groupe.name }),
    });
}

function addCompany(groupeID, companyID) {
    return fetch("api/groupes/add-company", {
        method  : "post",
        headers : {
            "Authorization" : store.state.token,
            "Content-Type"  : "application/json",
        },
        body    : JSON.stringify({
            companyID : companyID,
            groupeID  : groupeID,
        }),
    });
}

function removeCompany(groupeID, companyID) {
    return fetch("api/groupes/remove-company", {
        method  : "post",
        headers : {
            "Authorization" : store.state.token,
            "Content-Type"  : "application/json",
        },
        body    : JSON.stringify({
            companyID : companyID,
            groupeID  : groupeID,
        }),
    });
}


function remove(id) {
    return fetch("api/groupes/" + id, {
        method  : "delete",
        headers : {
            "Authorization" : store.state.token,
        },
    });
}
